

// //*----------------------------- bootstrap carousel ------------------*//

.carousel-indicators {

 li{
  border-radius: 50%;

 }
  .active {
    opacity: $carousel-indicator-active-opacity;
    background-color: $primary;
    width: 9px;
    height:9px;
  }
}

.carousel-icon {
  width: auto;
  .carousel-control-prev-icon {
    background-color: $carousel-control-bg-color;
    background-size: 70% 70%;
  
  }
  .carousel-control-next-icon {
    background-color: $carousel-control-bg-color;
    background-size: 70% 70%;
  }
 
  // Icons for within
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: $carousel-control-icon-width;
    height: $carousel-control-icon-width;
  }
}

@include media-breakpoint-up(sm) {
  .carousel-control-prev {
    left: -25px;
  }
  .carousel-control-next {
    right: -25px;
  }
}

@include media-breakpoint-up(md) {
  .carousel-control-prev {
    left: -30px;
  }
  .carousel-control-next {
    right: -30px;
  }
}
@include media-breakpoint-up(lg) {
  .carousel-control-prev {
    left: 0;
  }
  .carousel-control-next {
    right: 0;
  }
}
@include media-breakpoint-up(xl) {
  .carousel-control-prev {
    left: -30px;
  }
  .carousel-control-next {
    right: -30px;
  }
}
// @include media-breakpoint-up(xxl) {
//   .carousel-control-prev {
//     left: -70px;
//   }
//   .carousel-control-next {
//     right: -70px;
//   }
// }

// icon

@include media-breakpoint-up(md) {
  .carousel-control-prev.s-icon-prev {
    left: -20px;
  }
  .carousel-control-next.s-icon-next {
    right: -20px;
  }
}
@include media-breakpoint-up(lg) {
  .carousel-control-prev.s-icon-prev {
    left: -30px;
  }
  .carousel-control-next.s-icon-next {
    right: -30px;
  }
}

