/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */

.badge {
  line-height: 0.8125rem;
}

.badge-circle {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}

@include media-breakpoint-up(md) {
  .badge-circle {
    height: 3.4rem;
    width: 3.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    
  }
}

.badge-foodwagon {
  border-radius: 0 1.25rem 0 1rem;
}

.card-badge {
  position: absolute;
  top: 13rem;
  z-index: 1;
}

.card-actions {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
}